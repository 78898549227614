import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoint } from '../breakpoints/breakpoints';
import Layout from '../components/layout';
import Flex from '../components/flex';
import Home from '../icons/Home';

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.bgColor};
  padding: 20px 40px;

  @media ${breakpoint.mobileXL} {
    padding: 20px 100px;
  }

  @media ${breakpoint.tablet} {
    padding: 20px 150px;
  }
  @media ${breakpoint.laptop} {
    padding: 20px 200px;
  }

  & h1 {
    color: ${(props) => props.color1};
  }
  & h2 {
    color: ${(props) => props.color2};
  }
`;

const StyledHome = styled(Home)`
  width: 30px;
  margin: 30px 0 30px -15px;
  fill: ${(props) => props.color1};
  cursor: pointer;

  & :hover {
    fill: ${(props) => props.color2};
  }
`;

const Div = styled.div`
  margin: 20px;
  line-height: 100%;
`;

const H2 = styled.h2`
  font-size: 25px;
  line-height: 120%;
  padding-top: 60px;
`;

const H3 = styled.h3`
  margin: 0;
  padding: 5px 0 0 0;
`;

const P = styled.p`
  line-height: 120%;
  padding: 5px 0;
`;

function Impressum({ data }) {
  if (!data) return null;
  const pageData = data.allPrismicLanding.edges[0].node.data;
  const button = pageData.body.find(
    (slice) => slice.slice_type === 'button_on_hero'
  );

  return (
    <Layout>
      <StyledFlex
        column
        bgColor={pageData.bgcolor1}
        color1={pageData.captioncolor}
        color2={pageData.captioncolor}
      >
        <Link to="/">
          <StyledHome
            color1={button.items[0].button_color}
            color2={button.items[0].hover_color}
          />
        </Link>
        <h1>Impressum</h1>

        <H2>Angaben gemäß § 5 TMG</H2>

        <Div>
          <H3>Markus Lehnert</H3>
          <P>
            Spirituelle Hypnose Akademie
            <br />
            Solmsstraße 81
            <br />
            60486 Frankfurt
            <br />
            Deutschland
          </P>

          <P>und</P>
          <H3>Ines Rüfenacht</H3>
          <P>
            Bachstraße 2<br />
            3072 Ostermundigen
            <br />
            Schweiz
          </P>
        </Div>

        <Div>
          <H2>Kontakt</H2>
          <p>
            Telefon (DE): +49 69 25472722
            <br />
            Telefon (CH): Tel: +41 79 779 77 06
            <br />
            E-Mail (DE): markus.lehnert@spirituelle-hypnose.net
            <br />
            E-Mail (CH): ines.ruefenacht@spirituelle-hypnose.net
          </p>
        </Div>

        <Div>
          <H2>Redaktionell verantwortlich</H2>
          <p>
            Markus Lehnert
            <br />
            Solmsstraße 81
            <br />
            60486 Frankfurt
            <br />
            Deutschland
          </p>
          <p>
            Body Wisdom®️ ist eine{' '}
            <a
              href="https://www.tmdn.org/tmview/get-detail?st13=EM500000017169202"
              target="_blank"
              rel="noopener noreferrer"
            >
              EU-weit
            </a>{' '}
            und in der{' '}
            <a
              href="https://www.swissreg.ch/srclient/faces/jsp/trademark/sr300.jsp?language=de&section=tm&id=704878"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schweiz
            </a>{' '}
            eingetragene, geschützte Marke.
          </p>
        </Div>

        <Div>
          <H2>EU-Streitschlichtung</H2>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{' '}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
        </Div>

        <Div>
          <H2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</H2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </Div>

        <Div>
          <H2>Konzeption, Design, Programmierung</H2>
          <p>
            Flanke 7 GmbH - Agentur für Websites, Webshops und Online-Marketing.
          </p>
          <a href="http://www.flanke7.de/" target="_blank">
            flanke7.de
          </a>
        </Div>

        <Div>
          <H2>Bildmaterial</H2>
          <p>
            ©️ Carsten Czech - Pixelmacher.com
            <br />
            ©️ Marco2811 - Fotolia.com
            <br />
            ©️ Andreas Haertle - Fotolia.com
            <br />
            ©️ Nikki Zalewski - Fotolia.com
            <br />
            ©️ Peter Heckmeier - Fotolia.com
            <br />
            ©️ DOC RABE Media - Fotolia.com
            <br />
            ©️ majeco - Fotolia.com
            <br />
            ©️ ksena32@ukrpost.ua - Fotolia.com
            <br />
            ©️ Julia Shepeleva - Fotolia.com
            <br />
            ©️ dieter76 - Fotolia.com
            <br />
            ©️ Almgren - Fotolia.com
            <br />
            ©️ Deviant - Fotolia.com
            <br />
            ©️ BortN66 - Fotolia.com
            <br />
            ©️ andreiuc88 - Fotolia.com
            <br />
            ©️ pepe - Fotolia.com
            <br />
            ©️ Stephan Koscheck - Fotolia.com
            <br />
            ©️ Christoph Hähnel - Fotolia.com
            <br />
            ©️ Alexander Potapov - Fotolia.com
            <br />
            ©️ dampoint - Fotolia.com
            <br />
            ©️ svetlana larina - Fotolia.com
            <br />
            ©️ Joelle M - Fotolia.com
            <br />
            ©️ Nejron Photo - Fotolia.com
            <br />
            ©️ photocrew - Fotolia.com
            <br />
            ©️ veida - Fotolia.com
            <br />
            ©️ Olga Lyubkin - Fotolia.com
            <br />
            ©️ Visual Concepts - Fotolia.com
            <br />
            ©️ Misha - Fotolia.com
            <br />
            ©️ Elenathewise - Fotolia.com
            <br />
            ©️ Pixelrohkost - Fotolia.com
            <br />
            ©️ Stefan Körber - Fotolia.com
            <br />
            ©️ l-pics - Fotolia.com
            <br />
            ©️ Saruri - Fotolia.com
            <br />
            ©️ Jens Klingebiel - Fotolia.com
            <br />
            ©️ keller - Fotolia.com
            <br />
            ©️ Piotr Skubisz - Fotolia.com
            <br />
            ©️ Foto Factory - Fotolia.com
            <br />
            ©️ Tinka - Fotolia.com
            <br />
            ©️ Sara Robinson - Fotolia.com
            <br />
            ©️ Jörg Engel - Fotolia.com
            <br />
            ©️ Nafreal - sxc.hu
            <br />
            ©️ sculpies - Fotolia.com
            <br />
            ©️ Julian Weber - Fotolia.com
            <br />
            ©️ WONG SZE FEI - Fotolia.com
            <br />
            ©️ Pavel Klimenko - Fotolia.com
            <br />
            ©️ Sven_Vietense - Fotolia.com
            <br />
            ©️ SandyS - Fotolia.com
            <br />
            ©️ Media Rocks - Fotolia.com
            <br />
            ©️ Thomas Francois - Fotolia.com
            <br />
            ©️ suzannmeer - Fotolia.com
            <br />
            ©️ Nori - Fotolia.com
            <br />
            ©️ Helmut Singer - Fotolia.com
            <br />
            ©️ Tomas Sereda - Fotolia.com
            <br />
            ©️ Ben Burger - Fotolia.com
            <br />
            ©️ rolafoto - Fotolia.com
            <br />
            ©️ pb_pictures - Fotolia.com
            <br />
            ©️ photostefan - Fotolia.com
            <br />
            ©️ rudi1976 - Fotolia.com
            <br />
            ©️ werbefoto-burger.ch - Fotolia.com
            <br />
            ©️ barantza - Fotolia.com
            <br />
            ©️ frank peters - Fotolia.com
            <br />
            ©️ JiSIGN - Fotolia.com
            <br />
            ©️ AK-DigiArt - Fotolia.com
            <br />
            ©️ Calado - Fotolia.com
            <br />
            ©️ yvart - Fotolia.com
            <br />
            ©️ N-Media-Images - Fotolia.com
            <br />
            ©️ DOC RABE Media - Fotolia.com
            <br />
            ©️ ag visuell - Fotolia.com
            <br />
            ©️ Photosani - Fotolia.com
            <br />
            ©️ miamiamia (sxc.hu)
            <br />
            ©️ Lurdeza (sxc.hu)
            <br />
            ©️ Stauke - Fotolia.com
            <br />
            ©️ Schlierner - Fotolia.com
          </p>
        </Div>
      </StyledFlex>
    </Layout>
  );
}

export default Impressum;

export const impressumQuery = graphql`
  query ImpressumQuery {
    allPrismicLanding {
      edges {
        node {
          data {
            bgcolor1
            captioncolor
            body {
              ... on PrismicLandingDataBodyButtonOnHero {
                id
                slice_type
                items {
                  button_color
                  hover_color
                  text_color
                }
              }
            }
          }
        }
      }
    }
  }
`;
